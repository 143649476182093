@import '../../style/custonVariable';
.MainContainer{
    position: relative;
    padding: 15px;
    .ProfileDetails{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        .panel{
            border-radius: 2px;
            margin-bottom: $panel-margin-bottom;
            background-color: $color-white;
            box-shadow: 0 1px 20px rgba(0, 0, 0, .08);
            border: 1px solid transparent;
            .profileUser{
                display: grid;
                grid-template-columns: 4fr 10fr;
                padding-top: 15px;
                padding-bottom: 20px;
                max-width: 100%;
                align-items: center;
                @media (min-width: 1600px) { 
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
                @media (max-width: 991px) { 
                    grid-template-columns: 1fr;
                }
                .ProfileSection{
                    // display: grid;
                    // margin: 0px;
                    // grid-template-columns: 1fr;
                    padding: 10px;
                    align-items: center;
                    border-right: 1px solid #dbdbdb;
                    @media (max-width: 991px) { 
                        border-right: none;
                    }
                    .MainProfileDetails{
                        text-align: center;
                        .avatarImage{
                            overflow: visible;
                            border-radius: 50%;
                            width: 120px;
                            height: 120px !important;
                            margin: 0 auto;
                            position: relative;
                            max-width: 205px;
                            margin: 0 auto;
                            border: none;
                            .avatarEdit{
                                position: absolute;
                                right: 12px;
                                z-index: 1;
                                bottom: 0;
                                input{
                                    display: none;
                                }
                                label {
                                    color: #fff;
                                    display: inline-block;
                                    width: 34px;
                                    height: 34px;
                                    margin-bottom: 0;
                                    border-radius: 100%;
                                    background: #201e1ed4;
                                    // box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
                                    cursor: pointer;
                                    font-weight: normal;
                                    transition: all 0.2s ease-in-out;
                                    &::after{
                                        content: "\f040";
                                        font-family: 'FontAwesome';
                                        color: #fff;
                                        position: absolute;
                                        top: 10px;
                                        left: 0;
                                        right: 0;
                                        text-align: center;
                                        margin: auto;
                                        cursor: pointer;
                                        top: 5px;
                                    }
                                }
                            }
                            .avatarPreview{
                                width: 125px;
                                height: 125px;
                                position: relative;
                                border-radius: 100%;
                                // box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%)
                                img{
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 100%;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                }
                            }
                        }
                        .ProfileFullName{
                            margin-bottom: 6px;
                            margin-top: 10px;
                            font-size: 22px;
                           color: #333;
                        }
                        .ProfileUserName{
                            font-size: 15px;
                            margin-top: 10px;
                            margin-bottom: 5px;
                            color: rgb(88, 102, 110);
                        }
                        .UserEmail{
                            color: rgb(88, 102, 110);
                            margin-bottom: 25px;
                        }
                        .PasswordButton{
                            cursor: pointer;
                            background: #F9F9F9;
                            border: 1px solid #F4F4F4;
                            color: #685E9C;
                            display: block;
                            max-width: 300px;
                            margin: 3px auto;
                            padding: 8px 10px 11px 10px;
                            border-radius: 2px;
                            &:hover{
                                background: #7265ba;
                                border: 1px solid #7265ba;
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                        .kyc{
                            margin-top: 25px;
                            div{
                                border: 1px solid #d0cce2;
                                display: inline-block;
                                height: 43px;
                                vertical-align: middle;
                                align-items: center;
                                padding: 9px 25px 0px 20px;
                                color: #333;
                                border-radius: 4px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                .notVerified{
                                    color: #CB2323;
                                }
                                .Verified{
                                    color: #34A402;
                                }
                            }
                            .KycLink{
                                border: 1px solid #7265ba;
                                background-color: #7265ba;
                                padding: 8px 20px 0px 20px;
                                color: $color-white;
                                display: inline-block;
                                height: 43px;
                                vertical-align: middle;
                                margin-left: -6px;
                                border-top-right-radius: 3px;
                                border-bottom-right-radius: 3px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                // &.a{
                                //     color: $color-white;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                // }
                            }
                        }
                    }
                }
                .ExtraDetails{
                    padding: 0 20px 0 30px;
                    margin: 0;
                    border: none;
                    @media (max-width: 991px) { 
                        text-align: center;
                        padding: 0 20px 0 20px;
                    }
                    .rankText{
                        font-size: 22px;
                        letter-spacing: 0.5px;
                        color: #333;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        .rankName{
                            font-size: 20px;
                            &.orange{
                                color: #ef8727;
                            }
                        }
                    }
                    .packageRenewal{
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        padding-bottom: 30px;
                        @media (max-width: 991px) { 
                            display: block;
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-gap: 25px;
                        }
                        .package{
                            display: inline-block;
                            margin-right: 55px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            @media (max-width: 991px) { 
                                margin: 0;
                                padding: 10px 10px 23px 10px;
                                border: 1px solid #eee;
                                text-align: center;
                                padding: 10px 10px 15px 10px;
                                border-radius: 3px;
                                background: #f9f9f9;
                                width: 100%;
                            }
                            .Packagetext{
                                font-size: 15px;
                            }
                            .packageName{
                                font-size: 17px;
                                margin-bottom: 15px;
                                color: #000;
                            }
                            .packageButton{
                                margin-top: 5px;
                                .renewButton{
                                    padding: $button-padding;
                                    margin-bottom: 0;
                                    border-radius: 3px;
                                    letter-spacing: 0.2px;        
                                    background: $dashboard-color-blue;
                                    color: $color-white;
                                    font-weight: 400;
                                    border: 1px solid $dashboard-color-blue;
                                    font-size: 14px;
                                    display: inline-block;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .renew{
                            display: inline-block;
                            align-items: center;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            @media (max-width: 991px) { 
                                margin: 0;
                                padding: 10px 10px 23px 10px;
                                border: 1px solid #eee;
                                text-align: center;
                                padding: 10px 10px 15px 10px;
                                border-radius: 3px;
                                background: #f9f9f9;
                                width: 100%;
                            }
                            .ExpiryText{
                                font-size: 15px;
                            }
                            .ExpiryDate{
                                font-size: 17px;
                                margin-bottom: 15px;
                                color: #000;
                            }
                            .renewButtons{
                                margin-top: 5px;
                                .renewButton{
                                    padding: $button-padding;
                                    margin-bottom: 0;
                                    border-radius: 3px;
                                    letter-spacing: 0.2px;
        
                                    background: $dashboard-color-blue;
                                    color: $color-white;
                                    font-weight: 400;
                                    border: 1px solid $dashboard-color-blue;
                                    font-size: 14px;
                                    display: inline-block;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                    .sponsorPlacement{
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        div{
                            display: inline-block;
                            margin-right: 5%;
                            @media (max-width: 991px) { 
                                display: block;
                                margin-right: 0%;
                                text-align: center;
                                margin-bottom: 10px;
                                margin-top: 10px;
                            }
                            .textheader{
                                font-size: 15px;
                                margin-bottom: 0;
                                color: #58666e;
                            }
                            .textvalue{
                                margin-bottom: 0px;
                                font-size: 21px;
                                span{
                                    color: #5a5a5a;
                                }
                            }
                        }
                    }
                    .UserDetails{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        max-width: 768px;
                        border: 1px solid #eee;
                        border-radius: 4px;
                        padding: 10px 0;
                        width: 100%;
                        @media (max-width: 991px) { 
                            grid-template-columns: 1fr 1fr;
                        }
                        .PersonalDetails{
                            padding: 15px 20px;
                            &.verticalLine{
                                border-right: 1px solid #dbdbdb;
                                @media (max-width: 991px) { 
                                    border-right: none;
                                }
                            }
                            // padding-left: 40px;
                            // padding-top: 10px;
                            // padding-bottom: 0px;
                            .Icon{
                                width: 25px;
                                height: 25px;
                                border-radius: 100%;
                                text-align: center;
                                margin: 0 5px;
                                line-height: 20px;
                                &.bgPurple{
                                    background-color: $box-color-purple;
                                }
                                &.bgyellow{
                                    background-color: $box-color-yellow ;
                                }
                                &.bgskyblue{
                                    background-color: $box-color-sky-blue;
                                }
                                &.bglight{
                                    background: $box-color-light;
                                }
                            }
                            .userHead{
                                font-family: $font-mulish;
                                font-style: normal;
                                font-size: 22px;
                                line-height: 30px;
                                letter-spacing: 0.03em;
                            }
                            .userTitle{
                                color: #666666;
                                font-family: $font-mulish;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    .EditContent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        padding: 5px 0px;
        @media (max-width: 991px) { 
            grid-template-columns: 1fr;
            padding: 0;
        }
        .PersonalDetials,.BankDetials,.PaymentDetails,.Settings{
            padding: 10px 25px 25px 25px;
            margin-bottom: 0px;
            border-color: #dee5e7;
            border-radius: 2px;
            background-color: #fff;
            border: 1px solid transparent;
            box-shadow: 0 1px 20px rgba(0, 0, 0, .08);
        }
        .ContactDetails{
            grid-column: 2 / span 2;
            padding: 10px 25px 25px 25px;
            margin-bottom: 0px;
            border-color: #dee5e7;
            border-radius: 2px;
            background-color: #fff;
            border: 1px solid transparent;
            box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
            @media (max-width: 991px) { 
                grid-column: unset;
            }
            .ContactDetailsForms{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 5px;
                @media (max-width: 991px) { 
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    .KycDetials{
        .card{
            margin-bottom: 20px;
        }
        .cardBody{
            padding: 15px;
            .inputField{
                height:$input-height;
                color: $color-black;
            }
            .formButtons{
                margin-top: 26px;
                button{
                    height: $input-height;
                    border-radius: 0;
                    margin:  0 2px;
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
            }
            legend{
                display: block;
                width: 100%;
                padding: 0;
                margin-bottom: 21px;
                padding-bottom: 12px;
                font-size: 18px;
                line-height: inherit;
                color: #333;
                border: 0;
                border-bottom: 1px solid #e5e5e5;
            }
            .status{
                font-weight: 700;
                text-shadow: 0 1px 0 rgba(0,0,0,0.2);
                display: inline;
                padding: 0.3em .7em .5em;
                font-size: 75%;
                font-weight: bold;
                line-height: 1;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: .25em;
                &.pending{
                    background-color: #f0ad4e;
                }
                &.approved{
                    background-color: $color-success;
                }
            }
            .docFile{
                background-color: $color-primary;
                color: $color-white;
                border-color : $color-primary;
                margin-left: 2px;
                img{
                    width : 15px;
                    height : 20px;
                    border-radius: 0;
                }
            }
            .deletefiles{
                background-color: lightgrey;
                border-color: lightgrey;
                color: $color-black;
                padding: 1px 5px;
                font-size: 12px;
                line-height: 1.5;
                border-radius: 3px;
            }
            .nodata{
                margin-bottom: 20px;
                background-color: #fff;
                border: 1px solid #dee5e7;
                border-radius: 4px;
                -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                text-align: center;
                padding: 10px 0;
            }
        }
    }
}
.FormHead{
    display: grid;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    grid-gap: 5px;
    margin-bottom: 10px;
    .headeText{
        color: #333;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .editButton{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0px;
        border: 1px solid #7265ba;
        background: #7265ba;
        color: #fff;
        font-weight: 500;
        outline: 0!important;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
    }
}
.loginForms{
    // background-color: #f7f7f7 !important;
    padding: 5px 12px !important;
    border-radius: 3px!important;
    border: 1px solid #cfdadd;
    height: 34px;
    color: black !important;
    &:disabled{
        cursor: not-allowed;
    }
}
.loginFormsHasError{
    box-shadow: none;
    border: 1px solid #ead3d3 !important;
}
.isInvalid{
    margin-top: -1px;
    color: #a94442;
}
.required::after{
    content: "*";
    color: red;
    padding-left: 5px;
}
.Button{
    display: inline-block;
    padding: 7px 20px 8px 20px;
    margin-bottom: 0;
    border-radius: 3px;
    letter-spacing: 0.2px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    
    &.update{
        border: 1px solid #7265ba;
        background: #7265ba;
        color: #fff;
        margin-right: 10px;
    }
    &.cancel{
        border: 1px solid #dee5e7;
        color: #58666e!important;
        background-color: #fff !important;
        &:active,&:not(:disabled):not(.disabled):active:focus{
            box-shadow: none;
            background-color: transparent !important;
            border-color: #dee5e7 !important;
        }
        // border-bottom-color: #dee5e7;
        // -webkit-box-shadow: 0 1px 1px rgba(90,90,90,0.1);
        // box-shadow: 0 1px 1px rgba(90,90,90,0.1)
    }
}
.selectedOptions{
    position: absolute;
    width: 100%;
    min-height: -webkit-fill-available;
    vertical-align: middle;
    padding: 5px 12px !important;
}
.caretIcon{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    right: 10px;
    top: 45%;
}
.DropdownMain{
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 3px !important;
    border: 1px solid #cfdadd;
    height: 34px;
    &.mainlayout{
        padding-top:10px ;
    }
    .DropDownMenu{
        // background: red;
        top: -15px !important;
        width: 100%;
    }
    .disabledDropDown{
        button{
            width: 100%;
            text-align: left;
            background: transparent;
            background: #e9ecef;
            color: black;
            border: none;
            height: 34px;
            cursor:not-allowed;
            &::after{
                right: 5px;
                position: absolute;
                content: "\f078";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
            }
        }
    }
            .displayNone{
                display: none;
            }
            .displaBlock{
                display: block;
            }
}
.datePicker{
    width: 100%;
    height: 34px;
    padding: 5px 12px !important;
    border-radius: 3px!important;
    border: 1px solid #cfdadd;
    height: 34px;
    &:disabled{
        cursor: not-allowed;
    }
}


//modal style 
// modal header 
.ModalContent{
    border: none;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    background-color: $color-white;
    .ModalHeader{
        background-image: linear-gradient(to right, #7266ba, #4A3388);
        text-align: center;
        display: block;
        padding-right: 2rem;
        padding-left: 2rem;
        color: $color-white;
        border: none;
        h3{
            margin-top: 20px;
            margin-bottom: 10px;
            i{
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
            }
        }
        .ModalTitle{
            font-size: 30px;
        }
        p{
            color: $color-white;
        }
    }
    .ModalBody{
        padding-right: 5rem;
        padding-left: 5rem;
        .forgotTag{
            color: red;
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
            cursor: pointer;
        }
        .captcha{
            text-align: center;
        }
        .captchaReload{
            cursor: pointer;
        }
        .SuccessModalButtonClose{
            text-align: center;
            padding: 25px;
            button{
                background-color: transparent;
                color: #7266ba;
                border: 1px solid #cfdadd;
                padding: 10px;
            }
        }
        .actionButton{
            text-align: center;
            .cancelButton{
                border: 1px solid #cfdadd;
                background-color: transparent;
                color: $color-primary;
                height: $input-height;
                border-radius: 0;
            }
            .changeButton{
                border-radius: 0;
                color: $color-white;
                background-color: $color-primary;
                border-color: $color-primary;
                height: $input-height;
                margin-left: 5px;
            }
        }
    }
}
.stripe_id {
    color: #7265ba;
    font-weight: bold;
  font-size: 13px;
  line-height: 35px;
}
.stripe_desc{
    color: #7265ba;
    font-weight: bold;
  font-size: 13px;
  line-height: 35px;
}
.formGroup{
    //margin-bottom: 4px;
    margin-bottom: 20px;
    // margin-top: 20px;
}
.formsInputField{
    // background-color: #f7f7f7 !important;
    margin-top: 0px;
    margin-left: 11px;
height: 34px;
}
.mobile_num_fld_view{
    width: 100%;
    height: auto;
    float: left; 
}
.mobile_num_fld{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 65px 3fr;
    gap:11px;
    position: relative;
}
.mob_fld_lbl{margin-bottom: 0;position: relative;
    top: -3px;
    left: 9px;
    font-size: 14px;    
}


.imgPreviewContent{
    background-color: $color-white;
    img{
        width: 100%;
    }
    .PreviewFooter{
        border-top: none;
        .closeButton{
            color:#58666e;
            background-color : $color-white;
            box-shadow:0 1px 1px rgba(90,90,90,0.1);
            padding: 6px 12px;
        }
    }
}
