@import '../../style/custonVariable';
.container{
    padding: 15px 25px;
    .Card{
        margin-bottom: 20px;
        .CardBody{
            padding: 15px;
            .fileContent{
                border: 1px solid #bce8f1;
                .partyImageUpload{
                    padding: 15px;
                    .fileUplaod{
                        margin-bottom: 9px;
                        display: block;
                        overflow: hidden;
                        .thumbnail{
                            width: 50px;
                        }
                    }
                }
            }
            .removeBtn{
                position: absolute;
            }
            .uploadButtons{
                position: relative;
                overflow: hidden;
                display: inline-block;
                .fileUplaod{
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    transform: translate(-300px, 0) scale(4);
                    font-size: 23px;
                    direction: ltr;
                    cursor: pointer;
                }
            }
        }
        .datePicker{
            display: block;
            position: relative;
            float: none;
            inset: auto;
            margin: 0px;
            padding: 0px;
            width: 100% !important;
            .datePickerInput{
                padding-left: 40px;
                padding-right: 12px;
                position: relative;
                float: none;
                inset: auto;
                margin: 0px;
                background-color: #eee;
                opacity: 1;
            }
            .pickerIcon{
                top: 9px;
                left: 12px;
                border: none;
                cursor: pointer;
                display: block;
                height: 16px;
                line-height: 0;
                padding: 0;
                position: absolute;
                text-indent: -9000px;
                width: 16px;
            }
        }
    }
}