@import "../../style/custonVariable";
.container{
    padding: 15px;
    table{
        background: $color-white;
        .actionIcon{
            color: #7266ba;
            font-size: 18px;
        }
    }
}
.modalContent{
    border: none;
    width: 786px;
    .modalHeader{
        background-color: #7266ba;
        text-align: center;
        color: $color-white;
        display: block;
        button{
            padding: 1rem 1rem;
            margin: -38px -10px -25px auto;
        }
    }
    .invoiceBody{
        padding: 15px;
        .printButton{
            text-align: right;
            padding: 10px;
        }
        .printArea{
            border: 1px solid #dee5e7;
        }
        .bordered{
            td,th{
                border: 1px solid #ddd;
                vertical-align: middle !important;
            }
        }
        .tableBottom{
            tr{
                border-bottom: 1px solid #c8c8c8;
            }
            td{
                padding: 5px;
            }
        }
    }
}