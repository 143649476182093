@import '../../style/custonVariable';
.MainContainer{
    padding: 15px;
    .ManiContent{
        text-align: start;
        background: $panel-bg-color;
        padding: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 2px;
        border-color: #ddd;
        margin-bottom: 20px;
        border: 1px solid transparent;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        .ContentBody{
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 18px;
        }
    }
}

.payments{
    padding: 10px 30px;
    width: 100%;
    max-width: 1300px;
    .paymentContainer{
        background: #F6F6F6;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
        display: inline-block;
        width: 100%;
        padding: 10px 30px;
        legend{
            display: block;
            width: 100%;
            padding: 0;
            margin-bottom: 21px;
            padding-bottom: 12px;
            font-size: 18px;
            line-height: inherit;
            color: #333;
            border: 0;
            border-bottom: 1px solid #e5e5e5;
        }
    }
}
.addonNotification {
    margin: 0 auto 15px;
    
    .addonDescription {
        padding: 5px;
        display: grid;
        grid-template-columns: 35px 1fr;
        align-items: center;
        div {
            display: flex;
            align-items: center;
            grid-gap: 5px;
            gap: 5px;
        }
        .icon {
            font-size: 30px;
            color: #ffad31 !important;
        }
    }
}