@import '../../style/custonVariable';
.MainContainer{
    padding: 15px;
    .ManiContent{
        text-align: start;
        background: $panel-bg-color;
        padding: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 2px;
        border-color: #ddd;
        margin-bottom: 20px;
        border: 1px solid transparent;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        .ContentBody{
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 18px;
        }
    }
}

.selectPackage select{
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 3px;
}

.tabSection {
    display: table;
    padding: 10px 30px;
    width: 100%;
    max-width: 1300px;
    .tabContainer {
        background: #F6F6F6;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
        display: inline-block;
        width: 100%;
        padding: 10px 30px;
        .upgradeDetails {
            display: inline-block;
            border: 1px solid #cecece;
            border-radius: 3px;
            padding: 5px 10px;
            margin-top: 10px;
            div {
                display: inline-block;
                border-left: 1px solid #cecece;
                padding: 5px 25px 5px 25px;
                margin: 10px 8px;
            }
            div:first-child {
                border-left: 0px solid #eee;
                padding-left: 8px;
            }
        }
    }
}
.amount {
    display: table;
    border: 1px solid #F5E694;
    background: #FFF3B7;
    padding: 8px 20px 8px 20px;
    margin: 25px 0px 10px 0px;
    color: #222;
    font-size: 18px;
    border-radius: 4px;
}

