.app{
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background:#fff;
    &:after{
        position: absolute;
        top: 0; 
        bottom: 0;
        z-index: -1;
        display: block;
        width: inherit;
        background-color: #fff; 
        border: inherit;
        content: "";
    }
}
.loginContainer{
    width: 500px !important;
    max-width: 100%;
    background: rgba(255,255,255,1) !important;
    padding: 30px 30px 25px 30px;
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
    margin-top:7%;
    position: relative;
    .langContainer{display: block !important;}
  }

  .lg_login_btn_btm_1{
    width: 100%;
    text-align: center;
    color: #242424;
    margin-top: 20px;
    font-size: 14px;
    text-decoration: none;
    a{
        width: 100%;
        text-align: center;
        color: #70A9FF;
        font-size: 14px;
        text-decoration: none;
        &:hover{color: #3b6fbd;}
    }
}


  
  
