@import '../../style/custonVariable';
.container {
    padding: 15px;
    .cartItemImage {
        width: 280px;
        height: 270px;
        @media only screen and (max-width: 600px) {
            width: 246px;
        }
    }
    .itemTitle {
        margin-bottom: 5px;
        margin-top: 35px;
        text-align: center;
    }
    .categoryTxt {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;

        text-shadow: none;
        margin-left: 10px;
        color: #58666e;
        background-color: #edf1f2;
    }
    .textCenter {
        text-align: center;
    }
    .price {
        font-size: 15px;
        font-weight: 400;
        color: #7266ba;
        text-align: center;
    }
    .noGutter {
        margin-right: 0;
        margin-left: 0;
    }
    .wrapper {
        padding: 5px;
        background: $color-white;
    //     @media only screen and (max-width: 600px) {
    //         width: 48%;
    // float: left;
    //     }
       
    }
    .br {
        border-right: 1px solid #d4d6d6;
        // @media only screen and (max-width: 600px) {
        //     margin-top: -59px;
        //     float: right;
        //     width: 52%;
        // }
    }
    

    .noPadder {
        padding: 0 !important;
    }
}
.panel{
    padding: 15px;
}
.cartTable{
    margin-bottom: 20px;
    .cardBody{
        padding: 15px;
        @media only screen and (max-width: 600px) {
            overflow: auto;
        }
    }
    .cartTableBody{
        margin-bottom: 20px;
        @media only screen and (max-width: 600px) {
            margin-left: -17px;
        }
    }
    .checkOutButton{
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-primary;
        height: 34px;
        &::after{
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            border-style: solid;
            border-width: 0;
            outline: none;
        }
        i{
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: 10px;
            line-height: 30px;
            position: relative;
            float: left;
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
        }
    }
}
.checkoutContainer{
    padding: 15px;
    legend{
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 21px;
        padding-bottom: 12px;
        font-size: 18px;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }
    .wizard{
        padding: 25px;
    }
}
        .PackageCard{
            box-shadow: none;
            border-color: #dee5e7;
            border-radius: 2px;
            border: 1px solid transparent;
            .PackageCardBody{
                padding: 0;
            }
            table{
                border: 1px solid #dee2e6;
                .checkoutImage{
                    img{
                        width: 34px;
                        margin: 0 auto;
                        vertical-align: middle;
                        border-radius : 0
                    }
                }
                th,td{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 130px;
                    max-width: 130px;
                }
                .updateBtn{
                    background-color: $color-primary;
                    border-color: $color-primary;
                }
                .removeBtn{
                    background-color: $color-info;
                    border-color: $color-info;
                    margin-left: 10px;
                }
            }
        }
.continueButton{
    background-color: $color-primary;
    border-color: $color-primary;
    height: 34px;
    &:hover,&:disabled,&:active{
        background-color: $color-primary;
        border-color: $color-primary;
    }
}
.btnAddonSuccess {
    background: $color-success !important;
    border-color: $color-success !important;
    height: 34px;
    padding-top: 8px;
    padding-right: 5px;
    font-size: 13px;
    line-height: 12px;
    &:not(:disabled):not(.disabled):active,&:hover {
        background: $color-success;
        border-color: $color-success;
    }
    @media only screen and (max-width: 600px) {
        margin-bottom: 10px;
    
    }
}

.btnAddonInfo {
    background: $color-info !important;
    border-color: $color-info !important;
    height: 34px;
    padding-top: 8px;
    padding-right: 5px;
    font-size: 13px;
    line-height: 12px;
    &:not(:disabled):not(.disabled):active,&:hover,&:disabled {
        background: $color-info;
        border-color: $color-info;
    }
    @media only screen and (max-width: 600px) {
       margin-top: 10px;
    }
}
.btnPrimary{
    background: $color-primary;
    border-color: $color-primary;
    height: 34px;
    &:not(:disabled):not(.disabled):active,&:hover,&:disabled {
        background: $color-primary;
        border-color: $color-primary;
    }
}
.floatRight{
    float: right;
}
.btnAddon {
    i {
        position: relative;
        float: left;
        width: 34px;
        height: 34px;
        margin: -10px -16px;
        margin-right: 5px;
        line-height: 34px;
        text-align: center;
        background-color: rgba(0,0,0,0.1);
        border-radius: 2px 0 0 2px;        
    }
} 
.addressCard{
    border-radius: 2px;
    margin-bottom: 20px;
    border: 1px solid #dee5e7;
    // box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    .addressHeader{
        height: 47px;
        overflow: auto;
        margin-bottom: 5px;
        margin-top: 5px;
        .close{
            cursor: pointer;
            float: right;
            font-size: 15px;
            font-weight: 700;
            line-height: 1;
            text-shadow: 0 1px 0 #fff;
            filter: alpha(opacity=20);
            opacity: 1.2;
        }
    }
    .addressCardBody{
        padding: 0;
    }
}
.wizardButton{
    float: right;
}
.addAddressModal{
    .modalContent{
        border: none;
        background-color: $color-white;
    }
    .modalHeader{
        display: block;
        background-color: #7266ba;
        text-align: center;
        color: #fff;
        button{
            padding: 0;
            margin: -20px 0px 0px auto;
        }
    }
    .addressCardBody{
        padding: 15px;
    }
    .modalFooter{
        display: block;
    }
}
.w3{
    width: 3% !important;
}


.submittButton{
    background-color: $color-primary;
    border: none;
   
    &:hover{
        background-color: $color-primary;       
    }
    &:focus{
        background-color: $color-primary;        
    }
}

.submittButton:active{
    background-color: $color-primary !important; 
}
